<template>
  <div class="contas">
    <h1 class="titulo-card fs-4">Contas financeiras</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="handleAdicionar()" class="btn btn-success">
            <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Adicionar
          </button>
        </div>

        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>
      <div class="d-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Descrição</th>
              <th class="text-center" width="120">Saldo</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="wallet in wallets" :key="wallet.id" @click="handleEditaConta(wallet)" :class="cssWallet(wallet)" class="cursor-pointer">
                <td scope="row">{{wallet.description}}</td>
                <td class="text-center">R$ {{shared.moeda.toStringBrl(wallet.amount)}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import shared from '../modules/shared'
import model from '@/model'

export default {
  name: 'Wallets',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      wallets: [],
    }
  },
  methods: {
    handlePesquisa() {
      loading(true);

      api.get(`/wallets?${this.paginador.requestParams}&orderBy[description]`).then(res => {
        loading(false);
        this.wallets = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    handleAdicionar() {
      this.$router.push(`/configuracoes/contas-financeiras/novo`);
    },

    async handleEditaConta(conta) {
      this.$router.push(`/configuracoes/contas-financeiras/${conta.id}/edit`);
    },

    cssWallet(wallet) {
      if (wallet.active) {
        return 'text-info';
      }

      return 'text-muted';
    },
  },
  computed: {
    shared() {
      return shared;
    },
  },
  created() {
    this.handlePesquisa();
  }
}
</script>
